import { useState, useEffect } from "react";

const App = () => {
  const [demo1, setDemo1] = useState({
    days: null,
    hours: null,
    minutes: null,
    seconds: null,
  });
  const [timer1, setTimer1] = useState(null);

  const setTimerDemo1 = () => {
    const date = new Date();
    date.setFullYear(date.getFullYear() + 1);
    const countDownDate = date.getTime();

    let updatedValue = {};
    setTimer1(
      setInterval(() => {
        const now = new Date().getTime();

        const distance = countDownDate - now;

        updatedValue.days = Math.floor(distance / (1000 * 60 * 60 * 24));
        updatedValue.hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        updatedValue.minutes = Math.floor(
          (distance % (1000 * 60 * 60)) / (1000 * 60)
        );
        updatedValue.seconds = Math.floor((distance % (1000 * 60)) / 1000);
        setDemo1((demo1) => ({
          ...demo1,
          ...updatedValue,
        }));

        if (distance < 0) {
          clearInterval(timer1);
        }
      }, 1000)
    );
  };

  useEffect(() => {
    setTimerDemo1();
    return () => {
      clearInterval(timer1);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div
      className="min-h-screen bg-cover bg-center bg-no-repeat"
      style={{ backgroundImage: "url('/assets/images/bg/wicked.svg')" }}
    >
      <div className="flex flex-col items-center justify-center h-screen">
        <img
          src="/assets/images/logo/niamini.png"
          alt="Logo"
          className="h-36 mb-2 -mt-10"
        />
        <h1 className="text-6xl font-bold text-[#14202e] mb-4">Coming Soon</h1>
        <div class="text-[#ff9aa2] mt-10 ">
          Made by{" "}
          <a
            href="https://tesseract.technology"
            target="_blank"
            class="italic font-bold hover:text-blue-500 animate-pulse"
          >
            Tesseract technologies.
          </a>
        </div>
      </div>
    </div>
  );
};

export default App;
